import React, { useEffect } from 'react';
import { Card, Container, Grid, Typography } from '@mui/material';
import WorkInProgressPage from '../workInProgressPage';
import Biography from '../../components/biography';
import bio from '../../data/bio.json';

const AboutPage = () => {

  const [bioInfo, setBioInfo] = React.useState(bio);

  useEffect(() => {
    const birthYear = bioInfo.birth_year;
    const currentYear = new Date().getFullYear();
    const age = currentYear - birthYear;

    const fixedDescriptions = bioInfo.descriptions.map((description) => {
      return description.replace('AGE', age);
    }
    );

    setBioInfo({
      ...bioInfo,
      descriptions: fixedDescriptions
    });


  }
  , [bioInfo]);



  return (
    <Container >
      <Biography bioInfo={bioInfo} />
      </Container>
  );
};

export default AboutPage;
