import React, { useEffect } from 'react';
import { Card, Container, Divider, Grid, List, Typography } from '@mui/material';
import WorkInProgressPage from '../workInProgressPage';
import Biography from '../../components/biography';
import welcome from '../../data/welcome.json';
import Welcome from '../../components/welcome';

const MainPage = () => {

  return (
    <Container >
      <Welcome welcome={welcome} />

</Container>
  );
};

export default MainPage;
