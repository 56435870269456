import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import MainPage from './pages/mainPage';
import AboutPage from './pages/aboutPage';
import ProjectsPage from './pages/projectsPage';
import { ThemeProvider } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import lightTheme from './themes/lighttheme';
import darkTheme from './themes/darktheme';
import { Navigate } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import GalleryPage from './pages/galleryPage';
import PostsPage from './pages/postsPage';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Header />
        <Routes>
          <Route path="/home" element={<MainPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/posts" element={<PostsPage />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
          {/* Add more Route components for other paths */}
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
