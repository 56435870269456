import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import './style.css';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 0, 0,0.5)'
    },
    secondary: {
      main: '#f44336',
    },
  },
});


const Header = () => {
  return (
<ThemeProvider theme={theme}>
<AppBar position="static" sx={{ 
  // margin: '0 10px 0 10px',
}}>
  <Toolbar sx={{
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
  }}>
    
    <Link to="/home" className='button'>
      <Typography variant="h6" component="div" >
        Home
      </Typography>
    </Link>
    <Link to="/about" className='button'>
      <Typography variant="h6" component="div" >
        About
      </Typography>
    </Link>
    <Link to="/projects" className='button'>
      <Typography variant="h6" component="div" >
        Projects
      </Typography>
    </Link>
    <Link to="/gallery" className='button'>
      <Typography variant="h6" component="div" >
        Gallery
      </Typography>
    </Link>
    <Link to="/posts" className='button'>
      <Typography variant="h6" component="div" >
        Posts
      </Typography>
    </Link>
    
    <Typography variant="h5" component="div" className='title'>
      Cian Farrell
    </Typography>
    
  </Toolbar>
</AppBar>
</ThemeProvider>


  );
};

export default Header;
