import { Card, Grid, Typography, Box, Divider, Tooltip} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {React} from 'react';
import './style.css';

const Projects = (projects) => {
    const projectData = projects.projects;
    const {
        last_updated,
        username,
        name,
        bio,
        overview,
        data
    } = projectData;

    const formattedLastUpdated = new Date(last_updated).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        });

    return (
        <Card className="projects-card">
                <div style={{ paddingLeft: '98%' }}>
            <Tooltip title={`Last Updated: ${formattedLastUpdated}`} placement="left">
        <InfoIcon />
            </Tooltip>
            </div>
          <Typography variant="h4" color="textPrimary" gutterBottom>
            {name} - Projects
          </Typography>
          <Divider className="divider" style={{ marginBottom: '1rem' }} />
          <Typography variant="body1" color="textSecondary" gutterBottom className="overview">
            {overview}
          </Typography>
          <Typography variant="h5" color="textPrimary" gutterBottom>
            Projects
          </Typography>
          <Divider className="divider" style={{ marginBottom: '1rem' }} />
          <Grid container spacing={3}>
            {data.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <a href={item.url} target="_blank" rel="noreferrer" className="project-link">
                  <Box className="project-box" sx={{ bgcolor: 'background.paper', p: 2 }}>
                    <Typography variant="h6" color="textPrimary" gutterBottom>
                      {item.name}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                      {item.description}
                    </Typography>
                  </Box>
                </a>
              </Grid>
            ))}
          </Grid>
        </Card>
      );
    };


export default Projects;