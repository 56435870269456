import React, { useEffect } from 'react';
import { Card, Container, Grid, Typography } from '@mui/material';
import WorkInProgressPage from '../workInProgressPage';
import { GetImages } from '../../api/cloudinary';

const GalleryPage = () => {

   const [images, setImages] = React.useState([]);

    useEffect(() => {
    const fetchImages = async () => {
      const images = await GetImages();
        setImages(images);
    };
    fetchImages();
    }
    , []);



  return (
    <Container >
      <WorkInProgressPage />
        <p>{JSON.stringify(images)}</p>
      </Container>
  );
};

export default GalleryPage;
