import React from "react";
import { Card, Container, Divider, Grid, Typography, Tooltip, List, ListItem , Link, ListItemText, ListItemIcon} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const Welcome = (welcome) => {

    const formattedLastUpdated = new Date(welcome.welcome.last_updated).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        });


    return (
        <Container style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Card style={{ padding: '2rem', textAlign: 'center' }}>
            <div style={{ paddingLeft: '98%' }}>
        <Tooltip title={`Last Updated: ${formattedLastUpdated}`} placement="left">
      <InfoIcon />
        </Tooltip>
    </div>
                <Typography variant="h4" color="textPrimary" gutterBottom>
                    {welcome.welcome.welcomeTitle}
                </Typography>
                <Divider style={{ marginBottom: '1rem' }} />
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Container style={{ textAlign: 'center', margin: '1em 0' }}>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Emoji_u1f44b.svg/2048px-Emoji_u1f44b.svg.png" alt="wave" style={{ width: '80%' }} />
                        </Container>
                    </Grid>
                    <Grid item xs={8} sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                    {welcome.welcome.welcomeMessage}
                </Typography>
                </Grid>
                </Grid>
            <Divider style={{ margin: '2rem 0' }} />
            <Typography variant="body1" color="textSecondary" gutterBottom>
                {welcome.welcome.welcomeSubMessage}
            </Typography>
            <Divider style={{ margin: '2rem 0' }} />
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Socials
            </Typography>
            <Grid container spacing={3} style={{ marginTop: '1rem', verticalAlign: 'middle', justifyContent: 'center' , alignItems: 'center' }}>
                {welcome.welcome.socialMedia.map((item) => (
                    <Grid item xs={4} key={item.name}>
                        <a href={item.url} target="_blank" rel="noreferrer">
                            <img src={item.icon} alt={item.name} style={{ width: '25%' }} />
                        </a>
                    </Grid>
                ))}
            </Grid>
            <Divider style={{ margin: '2rem 0' }} />
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Useful Links
            </Typography>
            <List>
    {welcome.welcome.usefulLinks.map((item) => (
        <Link 
            href={item.url} 
            target="_blank" 
            rel="noreferrer" 
            key={item.name}
            style={{ textDecoration: 'none', color: 'inherit' }}
        >
            <ListItem button style={{ padding: '1rem 22rem'   }}>
                <ListItemIcon style={{ width: '25%' }}>
                    <img 
                        src={item.icon} 
                        alt={item.name} 
                        style={{ width: '2em' }} 
                    />
                </ListItemIcon>
                <ListItemText primary={item.name} />
            </ListItem>
        </Link>
    ))}
</List>

            </Card>
        </Container>
    );
    }


export default Welcome;