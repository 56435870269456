import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import underConstructionImage from '../../resources/images/underConstruction.jpg';


const WorkInProgressPage = () => {

    return (
        <Card maxWidth="md" 
        sx ={{
            margin: '2rem auto',
            padding: '2rem',
        }}
        >

            <Box
            component="img"
            sx={{
                height: 'auto',
                width: '90%',
                borderRadius: '10px',
                display: 'block',
                border: '1px solid #ccc',
                margin: '2rem auto',
            }}
            alt="The house from the offer."
            src={underConstructionImage}
            />       

            <Typography variant="h4" component="h1" align="center">
                This page is under construction.
            </Typography>
            <Typography variant="body1" component="p" align="center">
                Please check back later.
            </Typography>
     </Card>
    );
};

export default WorkInProgressPage;
