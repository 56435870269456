import React, { useEffect } from 'react';
import { Card, Container, Grid, Typography } from '@mui/material';
import WorkInProgressPage from '../workInProgressPage';
import Biography from '../../components/biography';
import projects from '../../data/projects.json';
import Projects from '../../components/projects';

const ProjectsPage = () => {

  return (
    <Container >
      <Projects projects={projects} />
      </Container>
  );
};

export default ProjectsPage;
