import React, { useEffect, useState } from 'react';
import { Box, Card, Container, Grid, Typography, Avatar, CardMedia, List, Divider, ListItem, ListItemText, Tooltip} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SchoolIcon from '@mui/icons-material/School';
import MediaCard from '../../components/mediaCard';

const Biography = (bioInfo) => {
    const  bio  = bioInfo.bioInfo;

    const {
        last_updated,
        name,
        profile_image,
        birth_year,
        hometown,
        education,
        email,
        favourite_items,
        descriptions,
        skills
      } = bio;



    const image = require(`../../resources/images/${profile_image}`);



    const formattedLastUpdated = new Date(last_updated).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    });




    return (
        <Card sx={{ margin: '2rem auto', padding: '2rem' }}>
    <div style={{ paddingLeft: '98%' }}>
        <Tooltip title={`Last Updated: ${formattedLastUpdated}`} placement="left">
      <InfoIcon />
        </Tooltip>
    </div>
        
       <Container
      maxWidth="md"
      sx={{
        padding: '2rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center', // Optional: Center items vertically
        justifyContent: 'space-between', // Optional: Distribute items horizontally
      }}
    >
      <div>
        <Typography variant="h4" color="textPrimary" gutterBottom>
          {name}
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          {`${hometown}`}
        </Typography>
      </div>

    <Avatar
    style={{ height: '8em', width: '8em' }}
    src={image}
    alt={name}
    sx={{ ml: 2 }} // Optional: Adjust margin for spacing
    variant='rounded'
    />



    </Container>
    
  
        <Container>
            <Typography variant="h6" color="textPrimary" gutterBottom>
            About Me
            </Typography>
        <Divider sx ={{marginBottom: '2rem'}}/>
        {
            descriptions.map((paragraph, index) => (
                <Typography variant="body1" color="textSecondary" paragraph key={index}>
                    {paragraph}
                </Typography>
            ))
        }
  
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Education
          </Typography>
          <Divider sx ={{marginBottom: '2rem'}}/>

          <List sx={{ margin: '0 2rem' }}>
          {
            education.map((item, index) => (
                <ListItem key={index}>
                    <SchoolIcon sx={{ mr: 1 , marginRight: '1rem'}} />
                    <ListItemText primary={`${item.degree} - ${item.university}`} secondary={`${item.start_year} - ${item.graduation_year}`} />
                </ListItem>
                ))
          }
            </List>
  
            <Typography variant="h6" color="textPrimary" gutterBottom>
            Skills
          </Typography>
          <Divider sx ={{marginBottom: '2rem'}}/>
          <Grid container spacing={2}>
            {skills.map((item, index) => (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3} style={{ margin: '1rem 0' }}>
                <Box sx={{ bgcolor: 'background.paper', boxShadow: 1, borderRadius: '1rem', padding: '1rem' , margin: 'auto'}}>
                  <Container sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                  {item.name}
                  <img src={item.image} alt={item.name} style={{ height: '4rem', width: 'auto' }} />
                  </Container>
                </Box>
              </Grid>
            ))}
          </Grid>
  
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Favourites
          </Typography>
          <Divider sx ={{marginBottom: '2rem'}}/>
            <Typography variant="body2" color="textSecondary" paragraph>
                Some of my personal favourites include:
            </Typography>
          <Grid container spacing={2}>
            {favourite_items.map((item, index) => (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                <MediaCard {...item} />
              </Grid>
            ))}
          </Grid>

         
        </Container>
      </Card>
    );
}
        
export default Biography;