import {React} from 'react';
import { Box, Container, Typography } from '@mui/material';

const MediaCard = (item) => {
    return (
        <Box
        style={{ 
            borderRadius: '10px 10px 10px 10px',
            backgroundImage: `url(${item.image})`, 
            backgroundSize: 'cover',
            height: '20em', 
            justifyContent: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
        }}
      >
        <Container sx={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            paddingTop: '1rem',
            color: 'white',  
            height: '100%',
            borderRadius: '10px 10px 10px 10px',
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',

        }}>
        <Typography variant="h6" >
          {item.name}
        </Typography>
        <Typography variant="body2" >
          {`${item.type} - ${item.genre}`}
        </Typography>
        {item.comment ? (
          <Typography variant="body2"  paragraph style={{ fontStyle: 'italic', margin: "1rem 0" }}>
            {item.comment}
          </Typography>
        ) : (
            <div style ={{height: '1rem'}}></div>
        )
    }
        </Container>
      </Box>
    );
}

export default MediaCard;