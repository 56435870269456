import { Cloudinary } from "@cloudinary/url-gen";

export const GetImages = async () => {
    const cloudName = 'Portfolio';
    const folderName = 'samples';

    // Initialize Cloudinary instance
    const cloudinary = new Cloudinary({
        cloud: {
            cloudName: cloudName
        }
    });

    // API URL to search for images in the specific folder
    const searchUrl = `https://api.cloudinary.com/v1_1/${cloudName}/resources/search`;

    // Prepare search query for the folder
    const searchParams = {
        expression: `folder:${folderName}`,
        max_results: 100  // Adjust max_results as needed
    };

    try {
        // Make API request to Cloudinary search endpoint using fetch
        const response = await fetch(searchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_CLOUDINARY_API_KEY}:${process.env.REACT_APP_CLOUDINARY_API_SECRET}`)
            },
            body: JSON.stringify(searchParams)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // Extract image URLs from the response
        const imageUrls = data.resources.map(resource => resource.secure_url);

        return imageUrls;
    } catch (error) {
        console.error("Error fetching images from Cloudinary:", error);
        return [];
    }
};
